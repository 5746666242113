<template>
  <div class="question-case-conent">
    <!--左右分屏(试题答题卡)-->
    <div class="Split-screen">
      <div class="lf">
        <div class="Hd">
            <div class="back"><el-button class="back-btn" @click="back()" icon="el-icon-back" type="primary" size="mini" plain round>返回</el-button></div>
            {{bookName}}
        </div>
        <div class="questions-list">
          <cace-home-work :homeworks="questionData"></cace-home-work>
        </div>
      </div>
      <div class="rg">
         <div class="flex-3">
           <div class="flex-center">学生：{{StuByUserData.stuName}}（{{StuByUserData.userName}}）</div>
           <div class="flex-rg"> 总得分：{{totalSumAll}}分</div>
         </div>
         <div class="Answer-sheet">
<!--           <div style="height: 1600px"></div>-->
           <div class="AnswerSheet">
             <div class="Acontent" v-for="(item,i) in formData" :key="i">
               <div class="Aname">{{item.name}}</div>
               <div class="Acn topic" v-for="(items,index) in item.list" :key="index">
                 <!--选择题-->
                 <div class="Alist" v-if="items.type==1">
                   <div class="A1">{{items.questionNo}}</div>
                   <div class="A2">
                     <div class="Aoption">
                       <div class="A-tem" v-for="(Aoption,Aop) in items.option" :key="Aop"
                            :class="{active:items.studentAnswer ==Aoption}"
                       >
                         {{Aoption}}
                       </div>
                     </div>
                     <div class="correct-Box">
                       <img v-if="items.isRight==1" src="../../assets/images/correct.png" alt="">
                       <img v-if="items.isRight==2" src="../../assets/images/error.png" alt="">
                       <img v-if="items.isRight==0" src="../../assets/images/error.png" alt="">
                         <span>得分：{{items.studentScore}}</span>
                     </div>

<!--                       <div class="correct-Box">-->
<!--                           <img v-if="items.isRight==0" src="../../assets/images/uncheck.png" alt="">-->
<!--                           <img v-if="items.isRight==1" src="../../assets/images/check.png" alt="">-->
<!--                           <img v-if="items.isRight==2" src="../../assets/images/check.png" alt="">-->
<!--                       </div>-->

                   </div>
                   <div class="A3" v-if="items.isRight==2">
<!--                     <div class="D1">-->
<!--                       <div class="D1-A1">评语:</div>-->
<!--                       <div class="D1-A2">{{items.description}}</div>-->
<!--                     </div>-->
<!--                     <div class="D1">-->
<!--                       <div class="D1-A1">错因:</div>-->
<!--                       <div class="D1-A2">{{items.errorReason}}</div>-->
<!--                     </div>-->
                   </div>

                 </div>
                 <!--填空题-->
                 <div class="Blist" v-else>
                   <div class="A1">{{items.questionNo}}</div>
                   <div class="A2">
                     <div class="Da-1" v-if="items.studentAnswer==''">未做</div>

                     <div class="Da-2" v-else>
                       <div class="AoptionB" >
                         <viewer :images="photo">
                           <span v-html="items.studentAnswer"></span>
                         </viewer>
                       </div>
                     </div>

                     <div class="correct-Box">
                       <img v-if="items.isRight==1" src="../../assets/images/correct.png" alt="">
                       <img v-if="items.isRight==2" src="../../assets/images/error.png" alt="">
                         <span>得分：{{items.studentScore}}</span>
                     </div>
<!--                       <div class="correct-Box">-->
<!--                           <img v-if="items.isRight==0" src="../../assets/images/uncheck.png" alt="">-->
<!--                           <img v-if="items.isRight==1" src="../../assets/images/check.png" alt="">-->
<!--                           <img v-if="items.isRight==2" src="../../assets/images/check.png" alt="">-->
<!--                       </div>-->

                   </div>
                   <div class="A3">
                     <div class="D1" v-if="items.description !=''&&items.description!=null">
                       <div class="D1-A1">评语:</div>
                       <div class="D1-A2">{{items.description}}</div>
                     </div>

                   </div>

                 </div>

               </div>

             </div>

           </div>
         </div>

      </div>
    </div>

  </div>

</template>


<script>
import { mapState } from 'vuex'
import {tKnowContentCorrectsObj} from '@/api/parent'
import caceHomeWork from "../../components/caceHomeWork";
export default {
  name: '',
  data() {
    return {
      questionData:[],
      formData:[],
        bookName:''
    }
  },
  created() {
    this.EducationCase = this.$route.query.EducationCase;
    this.clickFlag=this.$route.query.clickFlag;
    this.paperId=this.$route.query.testPaperId;
    this.bookName=this.$route.query.bookName;
    this.listObj()
  },
  components: {
    caceHomeWork
  },
  methods: {

   //返回
    back() {
      this.$router.go(-1);
    },

    //查询列表
    listObj() {
      let obj = {
        paperId:Number(this.paperId)
      }
      tKnowContentCorrectsObj(obj).then((res) => { // eslint-disable-line no-unused-vars
        if(res.code==200) {
          this.questionData = res.data.qus[0].question
          this.formData = res.data.qus[0].answerSheet
        }
      })
    },
    //我要提问-弹框
    IwantQuestionObj() {
      this.IwantQuestion = true
    }
  },
  computed:{
    ...mapState([
      'StuByUserData',
    ]),
    totalSumAll(){
      let totalSumAll = 0;
      this.formData.map((item) => {
        totalSumAll += item.sumScore
      })
      return totalSumAll
    },
  }

}
</script>
<style lang="scss" scoped>


.question-case-conent {
  width: 98%;
  height: 95%;
  margin: 20px auto;
  opacity: 0.9;
  /*左右分屏(试题答题卡)*/
  .Split-screen {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    .lf {
      width: 50%;
      height: 100%;
      background-color: #FFF;
      box-shadow: 0px 0px 2px rgba(255, 104, 32, 0.3);
      justify-content: flex-start;
      position: relative;

      .Hd {
        width: 100%;
          height: 40px;
          line-height: 40px;
        background-color: #37AEFF;
        text-align: center;
        font-size: 16px;
        color: #fff;
        margin-bottom: 10px;
        position: relative;
          .back {
              position: absolute;
              justify-content: flex-start;
              padding-left: 20px;
              box-sizing: border-box;
              .back-btn {
                  border: 1px solid #c4e7ff;padding: 0px 20px; height:32px;line-height: 32px; background-color: #37AEFF; color:#c4e7ff
              }
          }
      }
      .questions-list {
        overflow-y: auto;
          height: 92%;
      }

    }

    .rg {
      width: 49%;
        height: 100%;
      background-color: #FFF;
      box-shadow: 0px 0px 2px rgba(255, 104, 32, 0.3);
      box-sizing: border-box;
      justify-content: flex-end;

      .flex-3 {
        width: 96%;
        height: 35px;
        border-bottom:1px dashed #FF6820;
        font-size: 14px;
        margin: auto auto 10px auto;
        display: flex;
        align-items: center;
        .flex-center {
          width: 60%;
          flex: 1;
          justify-content: flex-start;
        }
        .flex-rg {
          width: 120px;
          text-align: right;
          justify-content: flex-end;
        }
      }
      .Answer-sheet {
          height: 92%;
          overflow-y: auto;
        .AnswerSheet {
          width: 96%;
          margin: auto;

          .Acontent {
            width: 100%;
            padding: 8px 20px;
            box-sizing: border-box;
            display: grid;
            .Aname {
              line-height: 40px;
              font-size: 14px;
              font-weight: bold;
            }
            .Acn {
              padding:  0px 30px;
              box-sizing: border-box;
            }
            .Alist {
              width: 100%;
              margin: 10px 0px;
              padding: 5px 0px;
              box-sizing: border-box;
              position: relative;
              .A1 {
                width: 30px;
                height: 24px;
                line-height: 24px;
                text-align: right;
                box-sizing: border-box;
                padding-right: 10px;
                float: left;
                position: absolute;
              }
              .A2 {
                width: 780px;
                padding-left: 40px;
                box-sizing: border-box;
                display: flex;
                float: left;
                /*选项*/
                .Aoption {
                  width: 620px;
                    padding-right: 20px;
                  display: flex;
                  .A-tem {
                    width: 40px;
                    height: 24px;
                    line-height: 24px;
                    text-align: center;
                    border: 1px solid #707070;
                    margin: 0 10px;
                  }

                }
                /*是否正确*/
                .correct-Box {
                  width: 30%;
                  img {
                    margin-top: 5px;
                      margin-right: 20px;
                  }
                }
                /*非选项*/
                .Foption {
                  width: 100%;
                  .A-tem {
                    width: 100%;
                    height: 30px;
                    border-bottom: 1px solid #303133;
                    margin: 0 10px;
                  }
                }
              }
              .A3 {
                width: 500px;
                float: left;
                .D1 {
                  width: 100%;
                  font-size: 14px;
                  box-sizing: border-box;
                  padding-left: 40px;
                  display: flex;
                  .D1-A1 {
                    width: 65px;
                    background-color: #FA6E86;
                    height: 24px;
                    line-height: 24px;
                  }
                  .D1-A2 {
                    line-height: 24px;
                    width: 390px;
                  }
                }
              }

            }
            .Blist {
              width: 100%;
              margin: 10px 0px;
              padding: 5px 0px;
              box-sizing: border-box;
              position: relative;
              .A1 {
                width: 30px;
                height: 24px;
                line-height: 24px;
                text-align: right;
                box-sizing: border-box;
                padding-right: 10px;
                float: left;
                position: absolute;
              }
              .A2 {
                width: 780px;
                padding-left: 40px;
                box-sizing: border-box;
                display: flex;
                float: left;
                .Da-1 {
                  width: 620px;
                  margin-top: 0px;
                    margin-right: 20px;
                  box-sizing: border-box;
                  background-color: #FBF8D9;
                  line-height: 24px;
                  border: 1px solid #e5e9f2;
                }
                .Da-2 {
                  width: 620px;
                  margin-top: 0px;
                  padding-right: 20px;
                  box-sizing: border-box;
                  line-height: 24px;
                  ::v-deep.AoptionB {
                    width: 100%;
                    display: flex;
                    p {
                      img {
                        width: 100%;
                        border: 1px solid #E5E5E5;
                      }
                    }
                  }
                }
                /*选项*/

                /*是否正确*/
                .correct-Box {
                  width: 30%;
                  img {
                      margin-top: 5px;
                      margin-right: 20px;
                  }
                }
                /*非选项*/
                .Foption {
                  width: 100%;
                  .A-tem {
                    width: 100%;
                    height: 30px;
                    border-bottom: 1px solid #303133;
                    margin: 0 10px;
                  }
                }

              }
              .A3 {
                width: 750px;
                float: left;
                .D1 {
                  width: 100%;
                  font-size: 14px;
                  box-sizing: border-box;
                  padding-left: 50px;
                  display: flex;
                  .D1-A1 {
                    height: 30px;
                    line-height: 30px;
                  }
                  .D1-A2 {
                    line-height: 30px;
                    width: 390px;
                  }
                }
              }

            }

          }

        }
      }


    }
  }




}
.active {
  color: #fff;
  background: #0055C5 ;
  opacity: 1;
}
.intlist {
  width: 98%;
  margin: auto;
  padding: 10px 0;
  display: flex;
  border-bottom: 1px solid #AFE6FF;
  .portrait {
    width: 6%;
    justify-content: flex-start;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50px;
    }
  }
  .intR {
    width: 94%;
    justify-content: flex-end;
    .tR01 {
      width: 100%;
      height: 40px;
      line-height: 40px;
      display: flex;
      .lf {
        width: 50%;
        color: #999999;
        text-align: left;
        justify-content: flex-start;
      }
      .rg {
        width: 50%;
        color: #999999;
        text-align: right;
        justify-content: flex-end;
      }
    }
    .tR02 {
      width: 100%;
    }
    .tR03 {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      div {
        vertical-align: middle;
        img {
          margin-right: 6px;
          vertical-align: middle;
        }
      }
    }
  }
}
.Lintlist {
  width: 94%;
  margin: auto 1% auto auto;
  padding: 10px 0;
  display: flex;
  float: right;
  border-bottom: 1px solid #AFE6FF;
  .portrait {
    width: 6%;
    justify-content: flex-start;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50px;
    }
  }
  .intR {
    width: 94%;
    justify-content: flex-end;
    .tR01 {
      width: 100%;
      height: 40px;
      line-height: 40px;
      display: flex;
      .lf {
        width: 50%;
        color: #999999;
        text-align: left;
        justify-content: flex-start;
      }
      .rg {
        width: 50%;
        color: #999999;
        text-align: right;
        justify-content: flex-end;
      }
    }
    .tR02 {
      width: 100%;
    }
    .tR03 {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      div {
        vertical-align: middle;
        img {
          margin-right: 6px;
          vertical-align: middle;
        }
      }
    }
  }
}

.active{
  border:3px solid #FF9100;
}
.scrollList{
  width:100%;
}
.pic-item{
  width:230px;
  height: 98px;
  background-color: red;
  margin-bottom: 10px;
}
.scroll-left{
  width:250px;
  height: 320px;
  float: left;
  .scrollbar{
    z-index: 1;
    height: 320px;
    //position: absolute;
    //left: 0;
    //overflow-x: hidden;
    //overflow-y: scroll;


  }
  ::v-deep.el-scrollbar {
    height: 100%;
  }
  ::v-deep.el-scrollbar__wrap {
    overflow: scroll;
    width: 100%;
    height: 120%;
  }
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 1;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #999;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #999;
}

</style>
